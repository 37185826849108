<template>

    <div class="not-found text-center py-5">

        <h1>404</h1>

        <h2>Данной страницы не существует</h2>

    </div>

</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

</style>
